export class SocialNetwork {
  constructor(socialNetwork = {}) {
    this.socialNetworkId = socialNetwork.socialNetworkId;
    this.profileSocialNetworkUrlPath = socialNetwork.profileSocialNetworkUrlPath;
    this.name = socialNetwork.name;
    this.allowedPrefixes = socialNetwork.allowedPrefixes;
    this.iconUrl = socialNetwork.iconUrl;
  }

  static fromJson(json) {
    return new SocialNetwork({
      socialNetworkId: json.social_network_id,
      profileSocialNetworkUrlPath: json.profile_social_network_url_path,
      name: json.name,
      allowedPrefixes: json.allowed_prefixes,
      iconUrl: json.icon_url,
    });
  }
}
