import { ALPHA_HEX_COLOR_REGEX } from '@/utils/regex';

export function isBase64(str) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function isAlphaHexColor(str) {
  return ALPHA_HEX_COLOR_REGEX.test(str);
}
