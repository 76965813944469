import { SocialLoginProvider } from '@/plugins/social-login/SocialLoginProvider';

// Fixes dual-screen position
function openWindowCentered(url, title, w, h) {
  //                       Most browsers          Firefox
  const dualScreenLeft = window.screenLeft || window.screenX;
  const dualScreenTop = window.screenTop || window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(url, title,
    `
      menubar=no,
      location=no,
      scrollbars=no,
      status=no,
      width=${w},
      height=${h},
      top=${top},
      left=${left}
    `,
  );

  if (window.focus) newWindow.focus();
  return newWindow;
}

export class AppleProvider extends SocialLoginProvider {
  constructor(accountsUrl, clientId) {
    super(clientId, { accountsUrl });

    this.accountsUrl = accountsUrl;
  }

  async install({ accountsUrl }) {
    const params = new URLSearchParams();
    params.set('scope', 'email name');
    params.set('response_type', 'code id_token');
    params.set('client_id', this.clientId);
    params.set('response_mode', 'form_post');
    params.set('redirect_uri', accountsUrl + '/oauth/apple-callback');
    params.set('state', JSON.stringify({ response_mode: 'post_message' }));

    this.oauthUrl = `https://appleid.apple.com/auth/authorize?${params}`;
  }

  async login() {
    const oauthWindow = openWindowCentered(this.oauthUrl, 'Apple Sign-In', 500, 700);

    return new Promise(resolve => {
      const onPostMessageReceived = event => {
        if (event.origin === this.accountsUrl && event.data?.token != null) {
          window.removeEventListener('message', onPostMessageReceived);
          return resolve(event.data.token);
        }
      };
      window.addEventListener('message', onPostMessageReceived);

      // Listener para saber se a janela foi fechada
      const timer = setInterval(function () {
        if (oauthWindow.closed) {
          clearInterval(timer);
          resolve(null);
        }
      }, 300);
    });
  }

  get name() {
    return 'apple';
  }

  get icon() {
    return require('@/assets/icons/apple.svg');
  }

  isActive () {
    return false;
  }
}
