export const DADOS_COMPLETOS_LOCALSTORAGE_KEY = 'ALL_DATA_FULFILLED';
export const ACCOUNTS_OAUTH_CALLBACK_URL = 'accounts_oauth_callback_url';

export const politicaDePrivacidadeHref = 'https://public-miscellaneous.s3-sa-east-1.amazonaws.com/termos/Poli%CC%81tica+de+Privacidade+-+Estrate%CC%81gia+Educacional.pdf';

export const checkoutSteps = [
  { id: 'produto', name: 'Produto' },
  { id: 'identificacao', name: 'Identificação' },
  { id: 'dados', name: 'Dados' },
  { id: 'pagamento', name: 'Pagamento' },
  { id: 'confirmacao', name: 'Confirmação' },
];

export const LOGOUT_REASON_KEY = 'logout_reason';

export const LOGOUT_REASON_MESSAGES = {
  LOGOUT: '',
  INACTIVATION: 'Você foi deslogado, pois o seu usuário não está ativo. Por favor, entre em contato com a central de atendimento ao aluno.',
  ADMIN_DECISION: 'Você foi desconectado. Por favor, realize o login novamente.',
  OVER_MAX_CONCURRENT_LOGIN: 'Você foi deslogado, pois uma nova sessão foi iniciada. Por favor, realize o login novamente.',
};

export const STATUS = {
  PAID: 'paid',
};

export const VENDIBLE_TYPE = {
  LOT: 'lote',
  PRODUCT: 'produto',
};

export const ALLOWED_POST_MESSAGE_ORIGINS = {
  sandbox: [
    'https://lp-med.estrategia-sandbox.com.br',
    'https://lp-oab.estrategia-sandbox.com.br',
    'https://lp-cj.estrategia-sandbox.com.br',
    'https://lp-militares.estrategia-sandbox.com.br',
    'https://lp-medicina.estrategia-sandbox.com.br',
    'https://lp-vestibulares.estrategia-sandbox.com.br',
    'https://lp-concursos.estrategia-sandbox.com.br',
  ],
  prod: [
    'https://lp-med.estrategia.com',
    'https://lp-oab.estrategia.com',
    'https://lp-cj.estrategia.com',
    'https://lp-militares.estrategia.com',
    'https://lp-medicina.estrategia.com',
    'https://lp-vestibulares.estrategia.com',
    'https://lp-concursos.estrategia.com',
  ],
  local: [
    'http://lp-med.local.estrategia-sandbox.com.br:3000',
    'http://lp-oab.local.estrategia-sandbox.com.br:3000',
    'http://lp-cj.local.estrategia-sandbox.com.br:3000',
    'http://lp-militares.local.estrategia-sandbox.com.br:3000',
    'http://lp-medicina.local.estrategia-sandbox.com.br:3000',
    'http://lp-vestibulares.local.estrategia-sandbox.com.br:3000',
    'http://lp-concursos.local.estrategia-sandbox.com.br:3000',
  ],
  qa: [
    'https://lp-med.estrategia-qa.com.br',
    'https://lp-oab.estrategia-qa.com.br',
    'https://lp-cj.estrategia-qa.com.br',
    'https://lp-militares.estrategia-qa.com.br',
    'https://lp-medicina.estrategia-qa.com.br',
    'https://lp-vestibulares.estrategia-qa.com.br',
    'https://lp-concursos.estrategia-qa.com.br',
  ],
};
