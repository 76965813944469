export class CreditCard {
  constructor(item = {}) {
    this.id = item.id;
    this.brand = item.brand;
    this.brandIcon = item.brandIcon;
    this.expirationMonth = item.expirationMonth;
    this.expirationYear = item.expirationYear;
    this.lastFourDigits = item.lastFourDigits;
    this.holderName = item.holderName;
  }

  static fromJson(json) {
    return new CreditCard({
      id: json.id ?? '',
      brand: json.brand ?? '',
      brandIcon: json.brand_icon ?? '',
      expirationMonth: json.expiration_month ?? '',
      expirationYear: json.expiration_year ?? '',
      lastFourDigits: json.last_four_digits ?? '',
      holderName: json.holder_name ?? '',
    });
  }
}
