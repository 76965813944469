export class Address {
  constructor(address = {}) {
    this.city = address.city;
    this.complement = address.complement;
    this.neighborhood = address.neighborhood;
    this.number = address.number;
    this.state = address.state;
    this.streetName = address.streetName;
    this.zipCode = address.zipCode;
  }

  static fromJson(json) {
    return new Address({
      city: json?.city ?? '',
      complement: json?.complement ?? '',
      neighborhood: json?.neighborhood ?? '',
      number: json?.number ?? '',
      state: json?.state ?? '',
      streetName: json?.streetName ?? '',
      zipCode: json?.zipCode ?? '',
    });
  }
}
