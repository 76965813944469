import { isAlphaHexColor, isBase64 } from '@/utils/string';
import { invalidBase64Error, invalidColorError } from '@/utils/errors';

export class Badge {
  constructor(badge = {}) {
    this.id = badge.id;
    this.color = badge.color;
    if (this.color && !isAlphaHexColor(this.color)) throw invalidColorError;
    this.icon = badge.icon;
    if (this.icon && !isBase64(this.icon)) throw invalidBase64Error;
  }

  static fromJson(json) {
    return new Badge({
      id: json?.id ?? '',
      color: json?.color ?? '',
      icon: json?.icon ?? '',
    });
  }
}
