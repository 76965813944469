export const ImagePreloadPlugin = {
  install: (Vue, images) => {
    Object.values(images).forEach((url) => {
      const img = new Image();
      img.src = url;
    });
    Vue.prototype.$image = {
      get(key) {
        if (key in images) {
          return images[key];
        } else {
          throw new Error('Imagem não cacheada');
        }
      },
    };
  },
};
