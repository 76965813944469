export const userAlreadyExistsError = new Error('User already exists');
export const incorrectCurrentPasswordError = new Error('Incorrect current password');
export const socialLoginProviderWithoutEmailError = new Error("Social login provider doesn't have email");
export const socialLoginProviderUnavailable = new Error('Social login provider temporarily unavailable');
export const resourceNotFoundError = new Error('Resource not found');
export const cpfAlreadyExistsError = new Error('CPF already exists');
export const invalidZipCodeError = new Error('Invalid zipcode');
export const notAuthenticatedError = new Error('Not authenticated');
export const notAuthorizedError = new Error('Not authorized');
export const invalidUserIdAndTokenCombinationError = new Error('Invalid userId and token combination');
export const userNotFoundError = new Error('User not found');
export const unconfirmedEmailError = new Error('Unconfirmed email');
export const emailDoNotRequireConfirmationError = new Error('Email do not require confirmation');
export const invalidColorError = new Error('Invalid aplha hex color');
export const invalidBase64Error = new Error('Invalid base64');
export const requestCanceledError = new Error('Request canceled by the user');
export const invalidTemplate = new Error('Invalid Template');
export const invalidDate = new Error('Invalid Date');
