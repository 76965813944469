import { PastPrice } from './pastPrice';

export class OrderItem {
  constructor(item = {}) {
    this.id = item.id ?? '';
    this.productId = item.productId ?? '';
    this.description = item.description ?? '';
    this.amountPaid = item.amountPaid ?? 0;
    this.expiresAt = item.expiresAt ?? null;
    this.vendibleType = item.vendibleType ?? '';
    this.interval = item.interval ?? 0,
    this.renovacaoAntecipadaPastPrices = item.renovacaoAntecipadaPastPrices ?? [];
  }

  static fromJson(json) {
    return new OrderItem({
      id: json.id ?? '',
      productId: json.product_id ?? '',
      description: json.description ?? '',
      amountPaid: json.amount_paid ?? 0,
      expiresAt: json.expires_at ?? null,
      vendibleType: json.vendible_type ?? '',
      interval: json.interval ?? 0,
      renovacaoAntecipadaPastPrices: json.renovacao_antecipada_past_prices?.map(i => PastPrice.fromJson(i)) ?? [],
    });
  }
}
