export class OrderRelationshipHistory {
  constructor(item = {}) {
    this.fromOrderId = item.fromOrderId ?? '';
    this.fromProductId = item.fromProductId ?? '';
    this.toOrderId = item.toOrderId ?? '';
    this.toOrderStatus = item.toOrderStatus ?? '';
    this.toProductId = item.toProductId ?? '';
    this.relationshipType = item.relationshipType ?? '';
  }

  static fromJson(json) {
    return new OrderRelationshipHistory({
      fromOrderId: json.from_order_id ?? '',
      fromProductId: json.from_product_id ?? '',
      toOrderId: json.to_order_id ?? '',
      toOrderStatus: json.to_order_status ?? '',
      toProductId: json.to_product_id ?? '',
      relationshipType: json.relationship_type ?? '',
    });
  }
}
