import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';

import 'flatpickr/dist/flatpickr.css';
import '@estrategiahq/coruja-web-ui/dist/coruja-tailwind.css';
import '@estrategiahq/coruja-web-ui/dist/coruja-web-ui.css';
import '@estrategiahq/coruja-web-ui/dist/icons/DesignSystemIcons.css';
import '@/assets/styles/index.css';
import '@/assets/styles/variables.css';

import App from './App.vue';
import { router } from './routing';

import { env, EnvPlugin } from '@/plugins/env';
import { ModalPlugin } from '@/plugins/modal';
import { ToastPlugin } from '@/plugins/toast';
import { ServicesPlugin } from '@/plugins/services';
import { SocialLoginPlugin } from '@/plugins/social-login';
import { FacebookProvider } from '@/plugins/social-login/FacebookSignIn';
import { GoogleProvider } from '@/plugins/social-login/GoogleSignIn';
import { AppleProvider } from '@/plugins/social-login/AppleSignIn';
import { ImagePreloadPlugin } from '@/plugins/image-preload';
import { VerticalPlugin } from '@/plugins/vertical';
import { PreviewModePlugin } from '@/plugins/preview';
import { createStore } from '@/store';
import { AccountsService } from '@/services/accounts';
import { BffService } from '@/services/bff';
import { events } from '@/services/events';
import { initializeValidations } from './validations';

Vue.config.productionTip = false;

// Preload das imagens (está no início pois executa assíncrono)
Vue.use(ImagePreloadPlugin, {
  'estrategia-logo': require('@/assets/images/estrategia-logo.svg'),
  'owl': require('@/assets/images/owl-minified.svg'),
  'loading-page-bg': require('@/assets/images/bg-minified.svg'),
});

Vue.use(VueGtm, {
  id: env.VUE_APP_GTM_ID,
  defer: true,
  debug: false,
  enabled: !!env.VUE_APP_GTM_ID,
  vueRouter: router,
});

Vue.use(EnvPlugin);
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);

const requesterId = 'perfil';
const services = {
  accounts: new AccountsService(env.VUE_APP_ACCOUNTS_URL, requesterId, router),
  bff: new BffService(env.VUE_APP_STUDENT_BFF_URL, requesterId, router),
  events: events,
};

Vue.use(ServicesPlugin, services);
Vue.use(SocialLoginPlugin, [
  new FacebookProvider(env.VUE_APP_FACEBOOK_CLIENT_ID),
  new GoogleProvider(env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID),
  new AppleProvider(env.VUE_APP_ACCOUNTS_URL, env.VUE_APP_APPLE_CLIENT_ID),
]);
Vue.use(PreviewModePlugin, router);
Vue.use(VerticalPlugin);

new Vue({
  router,
  store: createStore(services),
  render: h => h(App),
}).$mount('#app');

initializeValidations();
