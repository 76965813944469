export const env = window.__env__ || {};

env.VUE_APP_DOMAIN = env.VUE_APP_DOMAIN || process.env.VUE_APP_DOMAIN;
env.VUE_APP_OAB_WEB_URL = env.VUE_APP_OAB_WEB_URL || process.env.VUE_APP_OAB_WEB_URL;
env.VUE_APP_CONCURSOS_WEB_URL = env.VUE_APP_CONCURSOS_WEB_URL || process.env.VUE_APP_CONCURSOS_WEB_URL;
env.VUE_APP_CONCURSOS_LEGADO_WEB_URL = env.VUE_APP_CONCURSOS_LEGADO_WEB_URL || process.env.VUE_APP_CONCURSOS_LEGADO_WEB_URL;
env.VUE_APP_MEDICINA_WEB_URL = env.VUE_APP_MEDICINA_WEB_URL || process.env.VUE_APP_MEDICINA_WEB_URL;
env.VUE_APP_VESTIBULARES_WEB_URL = env.VUE_APP_VESTIBULARES_WEB_URL || process.env.VUE_APP_VESTIBULARES_WEB_URL;
env.VUE_APP_MILITARES_WEB_URL = env.VUE_APP_MILITARES_WEB_URL || process.env.VUE_APP_MILITARES_WEB_URL;
env.VUE_APP_CARREIRAS_JURIDICAS_WEB_URL = env.VUE_APP_CARREIRAS_JURIDICAS_WEB_URL || process.env.VUE_APP_CARREIRAS_JURIDICAS_WEB_URL;
env.VUE_APP_ACCOUNTS_URL = env.VUE_APP_ACCOUNTS_URL || process.env.VUE_APP_ACCOUNTS_URL;
env.VUE_APP_ADMIN_URL = env.VUE_APP_ADMIN_URL || process.env.VUE_APP_ADMIN_URL;
env.VUE_APP_STUDENT_BFF_URL = env.VUE_APP_STUDENT_BFF_URL || process.env.VUE_APP_STUDENT_BFF_URL;
env.VUE_APP_HELP_URL = env.VUE_APP_HELP_URL || process.env.VUE_APP_HELP_URL;
env.VUE_APP_GA_ID = env.VUE_APP_GA_ID || process.env.VUE_APP_GA_ID;
env.VUE_APP_GTM_ID = env.VUE_APP_GTM_ID || process.env.VUE_APP_GTM_ID;
env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID = env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID || process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID;
env.VUE_APP_FACEBOOK_CLIENT_ID = env.VUE_APP_FACEBOOK_CLIENT_ID || process.env.VUE_APP_FACEBOOK_CLIENT_ID;
env.VUE_APP_APPLE_CLIENT_ID = env.VUE_APP_APPLE_CLIENT_ID || process.env.VUE_APP_APPLE_CLIENT_ID;
env.VUE_APP_ALLOWED_PERFIL_HOSTS = env.VUE_APP_ALLOWED_PERFIL_HOSTS || process.env.VUE_APP_ALLOWED_PERFIL_HOSTS;
env.VUE_APP_ALLOWED_TARGET_HOSTS = env.VUE_APP_ALLOWED_TARGET_HOSTS || process.env.VUE_APP_ALLOWED_TARGET_HOSTS;
env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY = env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY || process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
env.VUE_APP_LOGIN_RECAPTCHA_ENABLED = env.VUE_APP_LOGIN_RECAPTCHA_ENABLED || process.env.VUE_APP_LOGIN_RECAPTCHA_ENABLED;
env.VUE_APP_NEW_CARD_RECAPTCHA_ENABLED = env.VUE_APP_NEW_CARD_RECAPTCHA_ENABLED || process.env.VUE_APP_NEW_CARD_RECAPTCHA_ENABLED;

export const EnvPlugin = {
  install(Vue) {
    Vue.prototype.$env = Vue.env = Object.freeze(env);
  },
};
