import dayjs from 'dayjs';
import { invalidTemplate, invalidDate } from './errors';

export const dateMinusYear = year => {
  return dayjs().subtract(year, 'year').format('YYYY-MM-DD');
};

export const formatDateBR = value => format(value, 'DD/MM/YYYY');

export const format = (value, template) => {
  if (!value) return null;
  if (!template) throw invalidTemplate;
  if (new Date(value).toDateString() === 'Invalid Date') throw invalidDate;
  return dayjs(value).format(template);
};

export const isAfter = (value, after) => {
  return dayjs(value).isAfter(after);
};

export const isBefore = (value, before) => {
  return dayjs(value).isBefore(before);
};

export const monthIntervalToText = (monthInterval) => {
  const res = [];
  if (monthInterval >= 12) {
    const years = Math.floor(monthInterval / 12);
    res.push(years === 1 ? '1 ano' : `${years} anos`);
    monthInterval -= years * 12;
  }
  if (monthInterval >= 1) {
    res.push(monthInterval === 1 ? '1 mês' : `${monthInterval} meses`);
  }
  return res.join(' e ');
};
