<template>
  <div
    v-if="isActive"
    class="c-bg-orange-300 ui-sticky ui-p-2 ui-top-0 ui-w-full c-d-flex c-center c-z-overlay"
  >
    <span class="c-text-white ui-flex-1 ui-px-4 c-text-b2 ui-text-center">
      Você está visualizando elementos em fase prévia.
    </span>

    <div class="c-d-flex c-vertical-center">
      <button @click="$emit('close')">
        <icon
          name="close"
          color="white"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'CPreviewBar',
  components: { Icon },
  props: {
    isActive: Boolean,
  },
};
</script>
