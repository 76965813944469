export const VerticalPlugin = {
  install: (Vue) => {
    const ALL_VERTICALS = [
      {
        id: 'concursos',
        color: '#6E58CD',
        title: 'Estratégia Concursos <span class="c-text-btn" style="color: #6E58CD">Novo!</span>',
        url: Vue.env.VUE_APP_CONCURSOS_WEB_URL,
        enabled: true,
      },
      {
        id: 'concursos-legado',
        color: '#6E58CD',
        title: 'Estratégia Concursos <span class="c-text-gray-300">(Atual)</span>',
        url: ({ token }) => Vue.env.VUE_APP_CONCURSOS_LEGADO_WEB_URL + `?access_token=${token}`,
        enabled: true,
      },
      {
        id: 'medicina',
        color: '#0BA4AE',
        title: 'Estratégia MED',
        url: Vue.env.VUE_APP_MEDICINA_WEB_URL,
        enabled: true,
      },
      {
        id: 'vestibulares',
        color: '#FF970D',
        title: 'Estratégia Vestibulares',
        url: Vue.env.VUE_APP_VESTIBULARES_WEB_URL,
        enabled: true,
      },
      {
        id: 'militares',
        color: '#3F7E67',
        title: 'Estratégia Militares',
        url: Vue.env.VUE_APP_MILITARES_WEB_URL,
        enabled: true,
      },
      {
        id: 'carreiras-juridicas',
        color: '#A36B00',
        title: 'Estratégia Carreira Jurídica',
        url: Vue.env.VUE_APP_CARREIRAS_JURIDICAS_WEB_URL,
        enabled: true,
      },
      {
        id: 'carreiras-juridicas-legado',
        color: '#A36B00',
        title: 'Estratégia Carreira Jurídica <span class="c-text-gray-300">(Atual)</span>',
        url: ({ token }) => Vue.env.VUE_APP_CONCURSOS_LEGADO_WEB_URL + `?access_token=${token}`,
        enabled: false,
      },
      {
        id: 'oab',
        color: '#AE0A0B',
        title: 'Estratégia OAB',
        url: Vue.env.VUE_APP_OAB_WEB_URL,
        enabled: true,
      },
    ];

    // Indexa as verticais
    const verticalsMap = ALL_VERTICALS.reduce((acc, vertical) => {
      acc[vertical.id] = vertical;
      return acc;
    }, {});

    Vue.prototype.$vertical = {
      getEnabled: () => ALL_VERTICALS.filter(v => v.enabled),
      getById: (id) => verticalsMap[id],
      getTitleById: (id) => verticalsMap[id]?.title,
      getUrl: (id) => verticalsMap[id]?.url,
    };
  },
};
