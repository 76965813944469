const prefix = 'global';
//mutations
const SET_PAGE_LOADING = `${prefix}:setPageLoading`;
//actions
const ACTION_UPDATE_PAGE_LOADING = `${prefix}:updatePageLoading`;
//getters
const GET_PAGE_LOADING = `${prefix}:getPageLoading`;

const state = {
  pageLoading: false,
};

const mutations = {
  [SET_PAGE_LOADING](state, value) {
    state.pageLoading = value;
  },
};

const actions = {
  [ACTION_UPDATE_PAGE_LOADING] ({ commit }, value) {
    commit(SET_PAGE_LOADING, value);
  },
};

const getters = {
  [GET_PAGE_LOADING](state) {
    return state.pageLoading;
  },
};

export const GlobalStoreKeys = {
  ACTION_UPDATE_PAGE_LOADING,
  GET_PAGE_LOADING,
  SET_PAGE_LOADING,
};

export const createGlobalStore = () => ({
  state,
  mutations,
  actions,
  getters,
});
