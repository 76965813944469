export class SocialLoginProvider {
  constructor(clientId, installProps = {}) {
    this.clientId = clientId;

    if (this.isActive()) {
      this.installed = this.install(installProps)
        .then(() => true)
        .catch((e) => {
          console.error(e);
          return false;
        });
    } else {
      this.installed = Promise.resolve(false);
    }
  }

  isActive () {
    return true;
  }

  get name() {
    throw new Error('Not implemented');
  }

  get icon() {
    throw new Error('Not implemented');
  }

  async login() {
    throw new Error('Not implemented');
  }

  async logout() {
    return undefined;
  }

  async install() {}
}
