<template>
  <div id="app">
    <theme-provider class="ui-h-screen">
      <c-preview-bar />

      <router-view />

      <c-modal />

      <c-toast
        id="toast"
        :auto-position="false"
        class="ui-fixed ui-mx-auto"
      />
    </theme-provider>
  </div>
</template>

<script>
import { ThemeProvider } from '@estrategiahq/coruja-web-ui';
import CModal from '@/plugins/modal/component';
import CToast from '@/plugins/toast/component';
import { mapGetters } from 'vuex';
import { UserStoreKeys } from '@/store/modules/user';
import { notAuthenticatedError } from './utils/errors';

export default {
  name: 'App',
  components: {
    ThemeProvider,
    CModal,
    CToast,
  },
  computed: {
    ...mapGetters({ user: UserStoreKeys.GET_LOGGED_USER }),
  },
  errorCaptured(e) {
    if (e === notAuthenticatedError) {
      this.$router.push({ name: 'logout' });
      return;
    }

    this.$toast.show({
      type: 'error',
      text: String(e).slice(0, 150),
      timeout: 0,
    });

    console.error(e);
  },
};
</script>

<style>
#app {
  /* Corrige scroll quando o dropdown está aberto */
  overflow: auto;
}

#toast {
  top: 0;
  width: 100%;
}

@media (min-width: 767px) {
  #toast {
    bottom: 1rem;
    left: 0;
    right: 0;
    margin-top: auto;
    width: 544px !important;
  }
}
</style>
