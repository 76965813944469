import { OrderItem } from './orderItem';

export class Recurrence {
  constructor(recurrence = {}) {
    this.id = recurrence.id ?? '';
    this.userId = recurrence.userId ?? '';
    this.status = recurrence.status ?? '';
    this.orderItems = recurrence.orderItems ?? [];
    this.amountsOptions = recurrence.amountsOptions ?? {};
    this.createdAt = recurrence.createdAt ?? '';
    this.orderId = recurrence.orderId ?? '';
    this.vertical = recurrence.vertical ?? '';
    this.titleVertical = recurrence.titleVertical ?? '';
    this.orderNumber = recurrence.orderNumber ?? '';
  }

  static fromJson(json) {
    return new Recurrence({
      id: json.id ?? '',
      userId: json.user_id ?? '',
      status: json.status ?? '',
      orderItems: json.order_items?.map(i => OrderItem.fromJson(i)) ?? [],
      amountsOptions: json.amounts_options ?? {},
      createdAt: json.created_at ?? '',
      orderId: json.order_id ?? '',
      vertical: json.vertical ?? '',
      titleVertical: json.title_vertical ?? '',
      orderNumber: json.order_number ?? '',
    });
  }
}
