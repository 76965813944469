import Vue from 'vue';

const EVENT_NAME = 'accounts_ga_event';
const GTM_USER_ID_KEY = 'GTM_USER_ID';

function sendAnalyticsEvent(category, action, label) {
  Vue.gtm.trackEvent({
    event: EVENT_NAME,
    event_category: category,
    event_action: action,
    event_label: label,
  });
}

export const events = {
  notifyManualLogin: () => sendAnalyticsEvent('login', 'senha'),
  notifyManualLogout: () => sendAnalyticsEvent('logout', 'logout'),
  notifyManualRegistration: () => sendAnalyticsEvent('cadastro', 'senha'),
  notifyPasswordRecovered: () => sendAnalyticsEvent('redefinir_senha', 'senha_redefinida', 'esqueceu_senha'),
  notifyPasswordChanged: () => sendAnalyticsEvent('redefinir_senha', 'senha_redefinida', 'alterar_senha'),
  notifyComplementaryDataFilled: () => sendAnalyticsEvent('cadastro', 'dados_complementares'),
  notifySocialLoginLogin: (provider) => sendAnalyticsEvent('login', 'social', provider),
  notifySocialLoginRegistration: (provider) => sendAnalyticsEvent('cadastro', 'social', provider),
  notifySocialLoginBinding: (provider) => sendAnalyticsEvent('vinculo', 'social', provider),
  notifyEmailConfirmationOnRegistrationActionBackToLogin: () => sendAnalyticsEvent('cadastro_ativar_email', 'voltar_login'),
  notifyEmailConfirmationOnRegistrationActionResendEmail: () => sendAnalyticsEvent('cadastro_ativar_email', 'reenviar_email'),
  notifyEmailConfirmationOnLoginActionBackToLogin: () => sendAnalyticsEvent('login_ativar_email', 'voltar_login'),
  notifyEmailConfirmationOnLoginActionResendEmail: () => sendAnalyticsEvent('login_ativar_email', 'reenviar_email'),
  notifyEmailConfirmationSuccess: () => sendAnalyticsEvent('sucesso_ativar_email', 'sucesso_ativar_email'),
};

// Usado pelo GTM para enviar o UserId em todos os eventos.
export const setGtmUserId = (userId) => localStorage.setItem(GTM_USER_ID_KEY, userId);

// Usado pelo GTM para mapear o UserId.
window.getGtmUserId = () => localStorage.getItem(GTM_USER_ID_KEY);
