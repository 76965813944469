import axios from 'axios';
import { getToken, setToken } from './token';
import { setGtmUserId } from './events';
import { User } from '@/models/user';
import {
  socialLoginProviderWithoutEmailError,
  socialLoginProviderUnavailable,
  userAlreadyExistsError,
  cpfAlreadyExistsError,
  invalidZipCodeError,
  notAuthenticatedError,
  userNotFoundError,
  unconfirmedEmailError,
  emailDoNotRequireConfirmationError,
  incorrectCurrentPasswordError,
  requestCanceledError,
  invalidUserIdAndTokenCombinationError,
  notAuthorizedError,
} from '@/utils/errors';
import { getCurrentQueryString } from '@/utils/url';
import Vue from 'vue';
import { LOGOUT_REASON_KEY } from '@/consts';

const API_TAG_ERROR_MAPPING = {
  'AUTH.SOCIAL_LOGIN_PROVIDER_WITHOUT_EMAIL': socialLoginProviderWithoutEmailError,
  'AUTH.SOCIAL_LOGIN_PROVIDER_UNAVAILABLE': socialLoginProviderUnavailable,
  'USER.INCORRECT_CURRENT_PASSWORD': incorrectCurrentPasswordError,
  'USER.EMAIL_ALREADY_EXISTS': userAlreadyExistsError,
  'USER.CPF_ALREADY_EXISTS': cpfAlreadyExistsError,
  'ADDRESS.INVALID_ZIPCODE': invalidZipCodeError,
  'AUTH.NOT_AUTHENTICATED': notAuthenticatedError,
  'AUTH.NOT_AUTHORIZED': notAuthorizedError,
  'AUTH.INVALID_USER_ID_AND_TOKEN_COMBINATION': invalidUserIdAndTokenCombinationError,
  'USER.NOT_FOUND': userNotFoundError,
  'AUTH.UNCONFIRMED_EMAIL': unconfirmedEmailError,
  'AUTH.EMAIL_DO_NOT_REQUIRE_CONFIRMATION': emailDoNotRequireConfirmationError,
};

export class AccountsService {
  constructor(host, requesterId, router) {
    this.requestTokens = {};

    this.client = new axios.create({
      baseURL: host,
      timeout: 30000,
      withCredentials: true,
      headers: {
        'x-requester-id': requesterId,
      },
    });

    this._configureClientInterceptors(router);
  }

  _configureClientInterceptors(router) {
    const _removeFromOngoingRequests = resp => {
      if (resp?.config?.url) {
        delete this.requestTokens[resp.config.url];
      }
    };
    const onApiSuccess = resp => {
      _removeFromOngoingRequests(resp);
      return resp.data.data;
    };
    const onApiError = resp => {
      _removeFromOngoingRequests(resp);
      if (axios.isCancel(resp)) {
        return Promise.reject(requestCanceledError);
      }
      const response = resp?.response;
      const data = response?.data;

      // Se não é um erro do Accounts, retorna o erro original
      if (!data) {
        return Promise.reject(resp);
      }

      const tag = data.error?.tag || '';
      if (tag === 'AUTH.USER_SESSION_EXPIRED') {
        const reason = data.meta?.reason || '';
        sessionStorage.setItem(LOGOUT_REASON_KEY, reason);
      }

      if (response?.status === 401 && getToken()) {
        const partner = localStorage.getItem('partner@estrategia') || null;
        localStorage.clear();
        if (partner) {
          localStorage.setItem('partner@estrategia', partner);
        }
        return router.push({ name: 'logout' });
      }

      // Se for um erro de API e for mapeado, estoura esse erro
      if (tag && tag in API_TAG_ERROR_MAPPING) {
        const err = API_TAG_ERROR_MAPPING[tag];
        err.meta = data.meta;
        throw err;
      }

      // Se não for mapeado, retorna o erro original (compatibilidade com quem usa da maneira antiga)
      return Promise.reject(resp);
    };
    this.client.interceptors.response.use(onApiSuccess, onApiError);

    this.client.interceptors.request.use(config => {
      const cancelTokenSource = axios.CancelToken.source();

      this.requestTokens[config.url] = cancelTokenSource;
      config.cancelToken = cancelTokenSource.token;

      return config;
    });
  }

  async login(email, password, recaptchaToken, mfaCode, oauthState) {
    const json = {
      email: email,
      password: password,
    };
    if (recaptchaToken) {
      json.recaptcha_token = recaptchaToken;
    }
    if (mfaCode && mfaCode.length > 0) {
      json.mfa_code = mfaCode;
    }
    if (oauthState && oauthState.length > 0) {
      json.oauth_state = oauthState;
    }

    const data = await this.client.post('/auth/login', json);
    setToken(data.token);
    setGtmUserId(data.id);

    return data;
  }

  /**
   * Valida se a sessão do usuário está ativa
   */
  async validate() {
    await this.client.get('/auth/session/me/validate');
  }

  /**
   * Chama o accounts para informar que o email está sendo confirmado
   * @param {string} id
   * @param {string} token
   * @returns {Promise<void>}
   */
  async confirmEmail(id, token) {
    await this.client.post('/auth/email-confirmation', { id, token });
  }

  /**
   * Remove o cookie de sessão do usuário no accounts.
   */
  logout() {
    return this.client.post('/auth/logout');
  }

  async socialLoginAuthenticate(providerName, token, oauthState) {
    const body = {
      'provider_name': providerName,
      token,
      'query_string_for_email_link': getCurrentQueryString(),
    };

    if (oauthState && oauthState.length > 0) {
      body.oauth_state = oauthState;
    }

    const user = await this.client.post('/auth/social-login/authenticate', body);

    setToken(user.token);
    setGtmUserId(user.id);
    return user;
  }

  socialLoginRegister(providerName, token, email) {
    return this.client.post('/auth/social-login/register', {
      provider_name: providerName,
      token,
      email,
      query_string_for_email_link: getCurrentQueryString(),
    });
  }

  sendResetPasswordEmail(email, target) {
    return this.client.post('/auth/reset-token', { email, target });
  }

  resetPassword(token, password, id) {
    return this.client.post('/auth/reset-token/password', { token, password, id });
  }

  /**
   *
   * @param {User} user
   * @param {string} password
   * @returns {Promise<User>}
   */
  async createUser(user, password) {
    const body = {
      full_name: user.name,
      cpf: user.cpf,
      mobile: user.phone,
      email: user.email,
      password: password,
      address: user.address,
      query_string_for_email_link: getCurrentQueryString(),
    };

    const createdUser = await this.client.post('/user/', body);

    setGtmUserId(createdUser.id);

    return createdUser;
  }

  resendConfirmationEmail(email) {
    return this.client.post('/auth/email-confirmation/resend', {
      email,
      query_string_for_email_link: getCurrentQueryString(),
    });
  }

  /**
   *
   * @param {User} user
   */
  updateComplementaryData(user) {
    const body = {
      full_name: user.name,
      cpf: user.cpf,
      mobile: user.phone,
      birthday: user.birthday,
      address: user.address,
    };
    return this.client.put('/bff/dados-complementares', body);
  }

  /**
   *
   * @param {string[]} additional_fields
   * @returns {Promise<User>}
   */
  getLoggedUser(additional_fields = ['socialnetworks', 'interests', 'badge']) {
    const set = new Set();
    additional_fields.forEach(field => set.add(field));

    const qs = new URLSearchParams();
    qs.append('embedded_fields', [...set].sort().join(','));

    return this.client.get('/user/me?' + qs.toString()).then(s => {
      const user = User.fromJson(s);
      setGtmUserId(user.id);
      return user;
    });
  }

  /**
 *
 * @param {string[]} additional_fields
 * @returns {Promise<User>}
 */
  getLoggedUserOauthCallbackURL(oauth) {
    const qs = new URLSearchParams();
    qs.append('state', oauth);

    return this.client.get('/user/me?' + qs.toString()).then(s => {
      const user = User.fromJson(s);
      setGtmUserId(user.id);
      return user;
    });
  }

  /**
   *
   * @param {User} user
   * @returns {Promise<User>}
   */
  updateLoggedUser(user) {
    const body = {
      full_name: user.name,
      mobile: user.phone,
      email: user.email,
      about_me: user.aboutMe,
      birthday: user.birthday,
      mfa_required: user.mfa_required,
    };

    return this.client.put('/user/me', body);
  }

  /**
   *
   * @param {string} oldPassword
   * @param {string} newPassword
   * @returns {Promise<User>}
   */
  updateLoggedUserPassword(oldPassword, newPassword) {
    const body = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    return this.client.put('/user/me/password', body);
  }

  updateLoggedUserPrivacy(data) {
    const body = {
      anonymized_areas: data.anonymizedAreas,
    };
    return this.client.put('/user/me/privacy', body);
  }

  /**
   *
   * @param {Blob} newPhoto
   * @returns {Promise<User>}
   */
  updateLoggedUserProfilePhoto(newPhoto) {
    const data = new FormData();
    data.append('file', newPhoto, 'image');

    return this.client.put('/user/me/photo', data);
  }

  /**
   *
   * @param {Address} address
   * @returns {Promise<User>}
   */
  updateLoggedUserAddress(address) {
    return this.client.put('/user/me/address', address);
  }

  /**
   *
   * @param {string} zipCode
   * @returns {Promise<Address>}
   */
  getAddressByZipCode(zipCode) {
    return this.client.get(`/address/${zipCode}`);
  }

  getSocialNetworks() {
    return this.client.get('/social-networks');
  }

  getInterests() {
    return this.client.get('/interests');
  }

  updatePubliclyAvailable(/*status*/) {
    if (Vue.prototype.$isPreview('fase2')) {
      return Promise.resolve({});
    }
    // return this.client.put('/user/me/publicly-available', { publicly_available: status });
    throw new Error('Not implemented');
  }

  async updateUserSocialNetwork(data) {
    return this.client.put('/user/me/social-networks', data);
  }

  async updateUserInterests(data) {
    return this.client.put('/user/me/interests', data);
  }

  cancelRequests() {
    for (const token in this.requestTokens) {
      this.requestTokens[token].cancel();
    }
    this.requestTokens = {};
  }

  async requestMFAToDeleteAccount() {
    return this.client.post('/auth/mfa-request-delete-account');
  }

  async deleteAccount(justification, mfaCode) {
    return this.client.put('/user/me/lgpd-delete-account', { justification, mfa_code: mfaCode });
  }

  async createTrialUser(data) {
    return this.client.post('/user/create-for-trial', data);
  }
}
