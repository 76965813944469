import PrivatePreviewBar from '@/plugins/preview/component';

const LOCALSTORAGE_KEY = 'USE_PREVIEW';
const QUERYSTRING_KEY = 'preview';

export const PreviewModePlugin = {
  install(Vue, router) {
    let isActive = false;

    // Seta o preview recebido na url via query params
    router.beforeEach((to, from, next) => {
      const routeQuery = to.query[QUERYSTRING_KEY];

      if (routeQuery) {
        localStorage.setItem(LOCALSTORAGE_KEY, routeQuery);
      }

      // Atualiza o isActive nas trocas de rota (localstorage não é reativo)
      if (localStorage.getItem(LOCALSTORAGE_KEY)) {
        isActive = true;
      }

      next();
    });

    Vue.prototype.$isPreview = (previewId) => {
      const localStorageItems = localStorage.getItem(LOCALSTORAGE_KEY)?.split('|') || [];
      return localStorageItems.includes(previewId) || localStorageItems.includes('all');
    };

    Vue.component('c-preview-bar', {
      render(createElement) {
        function close() {
          localStorage.removeItem(LOCALSTORAGE_KEY);

          // Força recarregar a página para esconder todos os componentes que estavam utilizando $isPreview
          // e remove a queryString "preview" da url para não ser reaplicada
          const url = new URL(window.location.href);
          url.searchParams.delete(QUERYSTRING_KEY);
          window.location.href = url.href;
        }

        return createElement(PrivatePreviewBar, {
          props: { isActive },
          on: { close },
        });
      },
    });
  },
};
