import { STATUS, VENDIBLE_TYPE } from '@/consts';
import { OrderItem } from './orderItem';
import { OrderRelationshipHistory } from './orderRelationshipHistory';

export class OrderRecurrenceItem {
  constructor(item = {}) {
    this.dueAt = item.dueAt ?? '';
    this.interval = item.interval ?? '';
    this.status = item.status ?? '-';
    this.amount = item.amount ?? 0;
  }

  static fromJson(json) {
    return new OrderRecurrenceItem({
      dueAt: json.due_at ?? '',
      interval: json.interval ?? 0,
      amount: json.amount ?? 0,
      status: json.status ?? '-',
    });
  }
}

export class BonusItem {
  constructor(item = {}) {
    this.id = item.id ?? '';
    this.name = item.name ?? '';
    this.isDiscountBonus = item.isDiscountBonus ?? false;
  }

  static fromJson(json) {
    return new BonusItem({
      id: json.id ?? '',
      name: json.name ?? '',
      isDiscountBonus: (json.earned_discount ?? 0) > 0,
    });
  }
}

export class Order {
  constructor(order = {}) {
    this.id = order.id ?? '';
    this.orderNumber = order.orderNumber ?? '';
    this.status = order.status ?? '';
    this.titleVertical = order.titleVertical ?? '';
    this.vertical = order.vertical ?? '';
    this.amount = order.amount ?? 0;
    this.paymentMethod = order.paymentMethod ?? '';
    this.contractDate = order.contractDate;
    this.boletoPdfUrl = order.boletoPdfUrl ?? '';
    this.pixQrCodeUrl = order.pixQrCodeUrl ?? '';
    this.pixQrCodeExpiresAt = order.pixQrCodeExpiresAt ?? null,
    this.pixQrCode = order.pixQrCode ?? '';
    this.supportUrl = order.supportUrl ?? '';
    this.orderItems = order.orderItems ?? [];
    this.itemsSlugsForCartUrl = order.itemsSlugsForCartUrl ?? [];
    this.payments = order.payments ?? [];
    this.isCancellable = order.isCancellable ?? false;
    this.bonuses = order.bonuses ?? [];
    this.relationshipHistory = order.relationshipHistory ?? [];
    this.recurrences = order.recurrences ?? [];
    this.is_nfe_product = order.is_nfe_product ?? false;
    this.is_nfe_service = order.is_nfe_service ?? false;
    this.nfe_pdf = order.nfe_pdf ?? '';
  }

  static fromJson(json) {
    return new Order({
      id: json.id ?? '',
      orderNumber: json.order_number ?? '',
      status: json.status ?? '',
      titleVertical: json.title_vertical ?? '',
      vertical: json.vertical ?? '',
      amount: json.amount ?? 0,
      paymentMethod: json.payment_method ?? '',
      contractDate: json.created_at,
      boletoPdfUrl: json.boleto_pdf_url ?? '',
      pixQrCodeExpiresAt: json.pix_expires_at ?? null,
      pixQrCodeUrl: json.pix_qrcode_url ?? '',
      pixQrCode: json.pix_qrcode ?? '',
      supportUrl: json.support_url ?? '',
      orderItems: json.order_items?.map(i => OrderItem.fromJson(i)) ?? [],
      itemsSlugsForCartUrl: json.items_slugs_for_cart_url ?? [],
      payments: json.payments ?? [],
      isCancellable: json.is_cancellable ?? false,
      bonuses: json.bonuses ?? [],
      relationshipHistory: json.relationship_history?.map(r => OrderRelationshipHistory.fromJson(r)) ?? [],
      recurrences: json.recurrences?.map(i => OrderRecurrenceItem.fromJson(i)) ?? [],
      is_nfe_product: json.is_nfe_product ?? false,
      is_nfe_service: json.is_nfe_service ?? false,
      nfe_pdf: json.nfe_pdf ?? '',
    });
  }

  static toBonusList(bonuses) {
    const receivedBonuses = [];

    if (!bonuses) {
      return receivedBonuses;
    }

    for (const bonus of bonuses) {
      if (bonus.earned_items?.length) {
        receivedBonuses.push(...bonus.earned_items.map(e => BonusItem.fromJson(e)));
      }

      if (bonus.earned_product_discounts?.length) {
        receivedBonuses.push(...bonus.earned_product_discounts.map(e => BonusItem.fromJson(e)));
      }

      if (bonus.earned_products?.length) {
        receivedBonuses.push(...bonus.earned_products.map(e => BonusItem.fromJson(e)));
      }
    }

    return receivedBonuses;
  }

  _previousOrder(item, relationshipType) {
    const productId = item.vendibleType === VENDIBLE_TYPE.LOT ? item.productId : item.id;
    const hasNext = (this.relationshipHistory || []).some(r => {
      return r.fromOrderId === this.id && r.toOrderStatus === STATUS.PAID && r.fromProductId === productId && r.relationshipType == relationshipType;
    });
    return hasNext;
  }

  _nextOrder(item, relationshipType) {
    const productId = item.vendibleType === VENDIBLE_TYPE.LOT ? item.productId : item.id;
    return (this.relationshipHistory || []).some(r => {
      return r.toOrderId === this.id && r.toProductId === productId && r.relationshipType == relationshipType;
    });
  }

  isDowngrade(item) {
    if (this.status !== STATUS.PAID) {
      return false;
    }
    return this._nextOrder(item, 'downgrade');
  }

  isDowngraded(item) {
    return this._previousOrder(item, 'downgrade');
  }

  isRenovacaoAntecipada(item) {
    if (this.status !== STATUS.PAID) {
      return false;
    }
    if (!item.renovacaoAntecipadaPastPrices?.length) {
      return false;
    }
    return this._nextOrder(item, 'renovacao_antecipada');
  }

  isRenovadoAntecipadamente(item) {
    return this._previousOrder(item, 'renovacao_antecipada');
  }

  isUpgrade(item) {
    if (this.status !== STATUS.PAID) {
      return false;
    }
    return this._nextOrder(item, 'upgrade');
  }

  isUpgraded(item) {
    return this._previousOrder(item, 'upgrade');
  }
}
