import { extend } from 'vee-validate';
import { required, email, confirmed, min, max, length } from 'vee-validate/dist/rules';
import {
  isCpfValid,
  isDateAfter,
  isDateBefore,
  isFullNameValid,
  validatePrefixUrl,
} from '@/utils/form-validators';

export function initializeValidations() {
  extend('length', length);
  extend('required', { ...required, message: '{_field_} é obrigatório' });
  extend('email', { ...email, message: '{_field_} inválido' });
  extend('confirmed', { ...confirmed, message: '{_field_} não são iguais' });
  extend('min', { ...min, message: (field, { length }) => `${field} precisa ter no mínimo ${length} caracteres` });
  extend('max', { ...max, message: (field, { length, _value_ }) => `Caracteres ${_value_.length}/${length}` });
  extend('cpf', { validate: cpf => isCpfValid(cpf), message: 'CPF inválido' });
  extend('full_name', { validate: name => isFullNameValid(name), message: 'Nome completo inválido' });
  extend('validURI', {
    validate: (value, { allowedPrefixes }) => validatePrefixUrl(value, allowedPrefixes),
    params: ['allowedPrefixes'],
    computesRequired: true,
  });
  extend('after', {
    validate: (value, { after }) => isDateAfter(value, after),
    params: ['after'],
    computesRequired: true,
  });
  extend('before', {
    validate: (value, { before }) => isDateBefore(value, before),
    params: ['before'],
    computesRequired: true,
  });
}
