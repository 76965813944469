import { Address } from './address';
import { SocialNetwork } from '@/models/socialNetwork';
import { Interest } from '@/models/Interest';
import { Badge } from '@/models/badge';

export class User {
  constructor(user = {}) {
    this.id = user.id ?? '';
    this.avatarUrl = user.avatarUrl ?? '';
    this.cpf = user.cpf ?? '';
    this.email = user.email ?? '';
    this.name = user.name ?? '';
    this.phone = user.phone ?? '';
    this.hasPassword = user.hasPassword ?? false;
    this.address = user.address || new Address();
    this.orders = user.orders || [];
    this.aboutMe = user.aboutMe;
    this.publiclyAvailable = user.publiclyAvailable;
    /** @type {SocialNetwork[]} */
    this.socialNetworks = user.socialNetworks || [];
    /** @type {Interest[]} */
    this.interests = user.interests || [];
    /** @type {Badge} */
    this.badge = user.badge || new Badge();
    this.birthday = user.birthday ?? '';
    this.anonymizedAreas = user.anonymizedAreas ?? [];
    this.mfa_required = user.mfa_required ?? false;
    this.bo_access = user.bo_access ?? false;
    this.oauth_callback_url = user.oauth_callback_url ?? undefined;
  }

  static fromJson(json) {
    const socialNetworks = json.social_networks?.map(s => SocialNetwork.fromJson(s));
    const interests = json.interests?.map(i => Interest.fromJson(i));

    return new User({
      id: json.id ?? '',
      avatarUrl: json.avatar_url ?? '',
      cpf: json.cpf ?? '',
      email: json.email ?? '',
      name: json.full_name ?? '',
      phone: json.mobile ?? '',
      hasPassword: json.has_password ?? false,
      address: Address.fromJson(json.address),
      aboutMe: json.about_me ?? '',
      publiclyAvailable: json.publicly_available ?? false,
      socialNetworks: socialNetworks ?? [],
      interests: interests ?? [],
      badge: Badge.fromJson(json.badge),
      birthday: json.birthday ?? '',
      anonymizedAreas: json.anonymized_areas ?? [],
      mfa_required: json.mfa_required ?? false,
      bo_access: json.bo_access ?? false,
      oauth_callback_url: json.oauth_callback_url ?? undefined,
    });
  }
}
