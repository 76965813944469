import { URI_SCHEME_REGEX } from './regex';
import { format, isAfter, isBefore } from './date';

const areAllDigitsEqual = (str) => str.split('').every(letra => letra === str[0]);

export function isCpfValid(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (areAllDigitsEqual(cpf)) return false;

  function validateDigit(digit) {
    let add = 0;
    const init = digit - 9;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i + init)) * (i + 1);
    }
    return (add % 11) % 10 === parseInt(cpf.charAt(digit));
  }

  return validateDigit(9) && validateDigit(10);
}

export function validatePrefixUrl(value, allowedPrefixes) {
  if (typeof allowedPrefixes === 'string') {
    allowedPrefixes = [allowedPrefixes];
  }
  const path = value.replace(URI_SCHEME_REGEX, '').toLowerCase();
  const hasPrefix = allowedPrefixes.some(prefix => path.startsWith(prefix.toLowerCase()));
  if (!hasPrefix) {
    return `URL deve iniciar com: ${allowedPrefixes.join(', ')}`;
  }
  return true;
}

export function isDateAfter(value, after) {
  if (isAfter(value, after)) {
    return true;
  }
  const date = format(after, 'DD/MM/YYYY');
  return `Data precisa ser depois de ${date}`;
}

export function isDateBefore(value, before) {
  if (isBefore(value, before)) {
    return true;
  }
  const date = format(before, 'DD/MM/YYYY');
  return `Data precisa ser antes de ${date}`;
}

export function isFullNameValid(name) {
  return name.includes(' ') && name.length > 3;
}
