import { deferredPromise, loadAsyncJsLib } from '@/utils/async';
import { SocialLoginProvider } from '@/plugins/social-login/SocialLoginProvider';
import { requestCanceledError } from '@/utils/errors';
import { isIOS } from '@/utils/navigator';

/*global gapi*/
export class GoogleProvider extends SocialLoginProvider {
  async install() {
    await loadAsyncJsLib('https://apis.google.com/js/api:client.js');

    const deferred = deferredPromise();

    gapi.load('auth2', () => {
      const googleAuth = gapi.auth2.init({
        client_id: this.clientId,
      });

      googleAuth.then(
        client => {
          this.client = client;
          deferred.resolve();
        },
        err => deferred.reject(err),
      );
    });

    await deferred.promise;
  }

  async login() {
    const resp = await this.client?.signIn().catch((e) => {
      // Se o usuário apenas fechar o popup, ignora
      if (e.error === 'popup_closed_by_user') {
        return null;
      } else {
        throw e;
      }
    });
    if (typeof resp?.getAuthResponse === 'function') {
      return resp?.getAuthResponse()?.id_token;
    }
    throw requestCanceledError;
  }

  async logout() {
    return this.client?.signOut();
  }

  get name() {
    return 'google';
  }

  get icon() {
    return require('@/assets/icons/google.svg');
  }

  isActive () {
    return !isIOS();
  }
}
