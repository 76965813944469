export class PastPrice {
  constructor(item = {}) {
    this.orderId = item.orderId ?? '';
    this.productId = item.productId ?? '';
  }

  static fromJson(json) {
    return new PastPrice({
      orderId: json.order_id ?? '',
      productId: json.product_id ?? '',
    });
  }
}
