const prefix = 'public-profile';
//mutations
const SET_INTERESTS = `${prefix}:setInterests`;
const SET_SOCIAL_NETWORKS = `${prefix}:setSocialNetworks`;
const SET_REQUEST_LOADING = `${prefix}:setRequestLoading`;
// actions
const ACTION_FETCH_INTERESTS = `${prefix}:fetchInterests`;
const ACTION_FETCH_SOCIAL_NETWORKS = `${prefix}:fetchSocialNetworks`;
// getters
const GET_INTERESTS = `${prefix}:getInterests`;
const GET_SOCIAL_NETWORKS = `${prefix}:getSocialNetworks`;
const GET_REQUEST_LOADING = `${prefix}:getRequestLoading`;

const state = {
  interests: [],
  socialNetworks: [],
  requestLoading: false,
};

const mutations = {
  [SET_INTERESTS](state, value) {
    state.interests = value;
  },
  [SET_SOCIAL_NETWORKS](state, value) {
    state.socialNetworks = value;
  },
  [SET_REQUEST_LOADING](state, value) {
    state.requestLoading = value;
  },
};

const createActions = (accountsService) => ({
  [ACTION_FETCH_SOCIAL_NETWORKS]({ commit }) {
    commit(SET_REQUEST_LOADING, true);
    return accountsService.getSocialNetworks().then(socialNetworks => {
      commit(SET_SOCIAL_NETWORKS, socialNetworks);
      commit(SET_REQUEST_LOADING, false);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
  [ACTION_FETCH_INTERESTS]({ commit }) {
    commit(SET_REQUEST_LOADING, true);
    return accountsService.getInterests().then(interests => {
      commit(SET_INTERESTS, interests);
      commit(SET_REQUEST_LOADING, false);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
});

const getters = {
  [GET_INTERESTS](state) {
    return state.interests;
  },
  [GET_SOCIAL_NETWORKS](state) {
    return state.socialNetworks;
  },
  [GET_REQUEST_LOADING](state) {
    return state.requestLoading;
  },
};

export const PublicProfileStoreKeys = {
  ACTION_FETCH_INTERESTS,
  ACTION_FETCH_SOCIAL_NETWORKS,
  GET_INTERESTS,
  GET_SOCIAL_NETWORKS,
  GET_REQUEST_LOADING,
};

export const createPublicProfileStore = (accountsService) => ({
  state,
  mutations,
  actions: createActions(accountsService),
  getters,
});
