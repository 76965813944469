import Vue from 'vue';
import Vuex from 'vuex';

import { createUserStore } from '@/store/modules/user';
import { createGlobalStore } from '@/store/modules/global.js';
import { createBffStore } from '@/store/modules/order';
import { createPublicProfileStore } from '@/store/modules/public-profile';

Vue.use(Vuex);

export const createStore = (services) => new Vuex.Store({
  strict: true,
  modules: {
    user: createUserStore(services.accounts),
    order: createBffStore(services.bff),
    global: createGlobalStore(),
    publicProfile: createPublicProfileStore(services.accounts),
  },
});
