export class Interest {
  constructor(Interest = {}) {
    this.interestId = Interest.interestId;
    this.name = Interest.name;
    this.color = Interest.color;
  }

  static fromJson(json) {
    return new Interest({
      interestId: json.interest_id,
      name: json.name,
      color: json.color,
    });
  }
}
