export async function loadAsyncJsLib(url) {
  return new Promise((resolve, reject) => {
    const newScript = document.createElement('script');
    newScript.onerror = (e) => reject(new Error(`Failed to load ${e.target.src}`));
    newScript.onload = () => resolve();

    document.head.appendChild(newScript);
    newScript.async = true;
    newScript.defer = true;
    newScript.crossOrigin = 'anonymous';
    newScript.src = url;
  });
}

export function delayInSeconds(seconds = 1) {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

export function deferredPromise() {
  let _resolve = (value) => {value};
  let _reject = (reason=null) => {reason};

  const promise = new Promise((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });

  return {
    promise,
    resolve: _resolve,
    reject: _reject,
  };
}
