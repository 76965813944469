export const SocialLoginPlugin = {
  install: (Vue, socialLoginProviders) => {
    const providers = {};
    socialLoginProviders.forEach(provider => providers[provider.name] = provider);

    Vue.prototype.$socialLogin = {
      authenticate: (providerName) => providers[providerName]?.login(),
      logoutAll: () => Promise.all(Object.values(providers).map(provider => provider.logout)),
      getIcon: (providerName) => providers[providerName]?.icon,
      isActive: (providerName) => providers[providerName].installed,
      someActive: async () => {
        const arr = await Promise.all(Object.values(providers).map(provider => provider.installed));
        return arr.some(active => active);
      },
    };
  },
};
