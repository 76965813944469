import axios from 'axios';
import { getTargetVerticalName } from '@/utils/url';
import { Order } from '@/models/order';
import { CreditCard } from '@/models/creditCard';
import { LOGOUT_REASON_KEY } from '@/consts';
import { Recurrence } from '@/models/recurrence';

export class BffService {
  constructor(host, requesterId, router) {
    const instance = {
      baseURL: host,
      timeout: 30000,
      withCredentials: true,
      headers: {
        'x-requester-id': requesterId,
      },
    };

    this.client = new axios.create(instance);
    this.clientDownload = new axios.create({
      ...instance,
      responseType: 'blob',
    });
    this.clientWithTargetVertical = new axios.create({
      ...instance,
      headers: {
        'x-requester-id': requesterId,
        'x-vertical': getTargetVerticalName(),
      },
    });

    const onApiSuccess = response => response;
    const onApiError = resp => {
      const response = resp?.response;
      const data = response?.data;
      const status = response?.status;

      if (status === 401) {
        const tag = data?.error?.tag || '';

        if (tag === 'AUTH.USER_SESSION_EXPIRED') {
          const reason = data?.meta?.reason || '';
          sessionStorage.setItem(LOGOUT_REASON_KEY, reason);
        }

        const partner = localStorage.getItem('partner@estrategia') || null;
        localStorage.clear();
        if (partner) {
          localStorage.setItem('partner@estrategia', partner);
        }
        return router.push({ name: 'logout' });
      }

      return Promise.reject(resp);
    };
    this.client.interceptors.response.use(onApiSuccess, onApiError);
    this.clientWithTargetVertical.interceptors.response.use(onApiSuccess, onApiError);
  }

  async getPurchaseHistory() {
    const orders = await this.get('/bff/profile/purchase-history');
    return orders.map(order => Order.fromJson(order));
  }

  async getFailedRecurrences() {
    const failedRecurrences = await this.get('/bff/profile/failed-recurrences');
    return failedRecurrences.map(r => Recurrence.fromJson(r));
  }

  /**
   * @param {string} orderNumber
   * @param {string} vertical
   * @returns {Promise<Order>}
   */
  async getOrderDetails(orderNumber, vertical) {
    const response = await this.get(`/bff/profile/order/${orderNumber}`, vertical);
    const order = Order.fromJson(response);
    return order;
  }

  async getOrderNfe(isProduct, orderNumber, vertical) {
    return await this.download(`/bff/ecommerce/orders/${orderNumber}/nfe?${isProduct ? 'is_nfe_product=true' : 'is_nfe_service=true'}`, vertical);
  }

  async getBonusesList(orderNumber, vertical) {
    const response = await this.get(`/bff/ecommerce/catalog/order/${orderNumber}`, vertical);
    return Order.toBonusList(response?.bonuses ?? []);
  }

  async get(url, vertical ) {
    const headers = {};
    if (vertical) { headers['x-vertical'] = vertical }
    return this.client.get(url, { headers }).then(resp => resp.data.data);
  }

  async download(url, vertical ) {
    const headers = {};
    if (vertical) { headers['x-vertical'] = vertical }
    return this.clientDownload.get(url, { headers }).then(resp => resp.data);
  }

  /**
   * @returns {Promise<CreditCard[]>}
   */
  getCreditCards() {
    return this.get('/bff/profile/credit-cards').then(data => data.map(cc => CreditCard.fromJson(cc)));
  }

  createCreditCard(payload) {
    return this.client.post('/bff/profile/credit-cards', payload);
  }

  deleteCreditCard(cardId) {
    return this.client.delete('/bff/profile/credit-cards/'+cardId);
  }

  setUtm ({ source, medium, campaign }) {
    const correctParams = {
      utm_source: source,
      utm_medium: medium,
      utm_campaign: campaign,
    };

    return this.clientWithTargetVertical.post('/bff/ecommerce/utm', correctParams);
  }

  async getPartnerBySlug(slug) {
    return this.client.get(`/bff/company/${slug}`).then(resp => resp.data);
  }

  async createTrialAccessByGoalId(goalId) {
    return this.clientWithTargetVertical.post(`/bff/goals/userGoals/trial-access/${goalId}`);
  }
}
