import { User } from '@/models/user';

const prefix = 'user';
//mutations
const SET_LOGGED_USER = `${prefix}:setLoggedUser`;
const SET_REQUEST_LOADING = `${prefix}:setRequestLoading`;
// actions
const ACTION_FETCH_LOGGED_USER = `${prefix}:fetchLoggedUser`;
const ACTION_UPDATE_LOGGED_USER = `${prefix}:updateLoggedUser`;
const ACTION_UPDATE_LOGGED_USER_ADDRESS = `${prefix}:updateLoggedUserAddress`;
const ACTION_UPDATE_LOGGED_USER_PHOTO = `${prefix}:updateLoggedUserPhoto`;
const ACTION_UPDATE_LOGGED_USER_PASSWORD = `${prefix}:updateLoggedUserPassword`;
const ACTION_UPDATE_LOGGED_USER_PRIVACY = `${prefix}:updateLoggedUserPrivacy`;
const ACTION_UPDATE_LOGGED_USER_PUBLIC_PROFILE = `${prefix}:updateLoggedUserPublicProfile`;
// getters
const GET_LOGGED_USER = `${prefix}:getLoggedUser`;
const GET_REQUEST_LOADING = `${prefix}:getRequestLoading`;

const state = {
  loggedUser: new User(),
  requestLoading: false,
};

const mutations = {
  [SET_LOGGED_USER](state, user) {
    state.loggedUser = user;
  },
  [SET_REQUEST_LOADING](state, value) {
    state.requestLoading = value;
  },
};

// usado para aproveitar a mesma chamada de getLoggedUser
// onde 2+ componentes concorrentemente solicitam os dados
const _loggedUser = {
  throttleLimit: 400, // milliseconds
  promise: Promise.resolve(new User()),
  lastCalled: null,
};

const createActions = (accountsService) => ({
  [ACTION_FETCH_LOGGED_USER]({ commit }) {
    commit(SET_REQUEST_LOADING, true);
    const now = new Date();
    if (!_loggedUser.lastCalled || (now - _loggedUser.lastCalled) > _loggedUser.throttleLimit) {
      _loggedUser.lastCalled = now;
      _loggedUser.promise = accountsService.getLoggedUser();
    }
    return _loggedUser.promise.then(user => {
      commit(SET_LOGGED_USER, user);
      commit(SET_REQUEST_LOADING, false);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
  [ACTION_UPDATE_LOGGED_USER]({ commit, dispatch }, payload) {
    commit(SET_REQUEST_LOADING, true);
    return accountsService.updateLoggedUser(payload).then(() => {
      dispatch(ACTION_FETCH_LOGGED_USER);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
  [ACTION_UPDATE_LOGGED_USER_ADDRESS]({ commit, dispatch }, payload) {
    commit(SET_REQUEST_LOADING, true);
    return accountsService.updateLoggedUserAddress(payload).then(() => {
      dispatch(ACTION_FETCH_LOGGED_USER);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
  [ACTION_UPDATE_LOGGED_USER_PHOTO]({ commit, dispatch }, payload) {
    commit(SET_REQUEST_LOADING, true);
    return accountsService.updateLoggedUserProfilePhoto(payload).then(() => {
      dispatch(ACTION_FETCH_LOGGED_USER);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
  [ACTION_UPDATE_LOGGED_USER_PASSWORD]({ commit, dispatch }, { oldPassword, newPassword }) {
    commit(SET_REQUEST_LOADING, true);
    return accountsService.updateLoggedUserPassword(oldPassword, newPassword).then(() => {
      dispatch(ACTION_FETCH_LOGGED_USER);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
  [ACTION_UPDATE_LOGGED_USER_PRIVACY]({ commit, dispatch }, payload) {
    commit(SET_REQUEST_LOADING, true);
    return accountsService.updateLoggedUserPrivacy(payload).then(() => {
      dispatch(ACTION_FETCH_LOGGED_USER);
    }).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
  },
  async [ACTION_UPDATE_LOGGED_USER_PUBLIC_PROFILE]({ commit, dispatch }, payload) {
    commit(SET_REQUEST_LOADING, true);
    //enquanto não temos um bff pro perfil será feito uma request por objeto
    await Promise.all([
      accountsService.updateUserSocialNetwork({ social_networks: payload.social_networks }),
      accountsService.updateUserInterests({ interests: payload.interests }),
      accountsService.updateLoggedUser(payload),
    ]).catch(err => {
      commit(SET_REQUEST_LOADING, false);
      throw err;
    });
    return dispatch(ACTION_FETCH_LOGGED_USER);
  },
});

const getters = {
  [GET_LOGGED_USER](state) {
    return state.loggedUser;
  },
  [GET_REQUEST_LOADING](state) {
    return state.requestLoading;
  },
};

export const UserStoreKeys = {
  ACTION_FETCH_LOGGED_USER,
  ACTION_UPDATE_LOGGED_USER,
  ACTION_UPDATE_LOGGED_USER_ADDRESS,
  ACTION_UPDATE_LOGGED_USER_PHOTO,
  ACTION_UPDATE_LOGGED_USER_PASSWORD,
  ACTION_UPDATE_LOGGED_USER_PRIVACY,
  ACTION_UPDATE_LOGGED_USER_PUBLIC_PROFILE,
  GET_LOGGED_USER,
  GET_REQUEST_LOADING,
};

export const createUserStore = (accountsService) => ({
  state,
  mutations,
  actions: createActions(accountsService),
  getters,
});
