const TOKEN_LOCALSTORAGE_KEY = 'TOKEN';

/**
 * @deprecated
 *
 * Não é necessário manipular o token diretamente, e em breve não será mais possível.
 *
 * Existe um cookie de sessão contendo o token que será enviado automaticamente **pelo browser**
 * para todos os domínios da Estratégia que possuírem o mesmo domínio e TLD (Top-Level-Domain).
 *
 * Exemplo: O cookie setado pelo "accounts.estrategia.ws" será enviado automaticamente
 * para todas as chamadas em que o domínio da API termine com ".estrategia.ws".
 */
export const getToken = () => {
  const token = document?.cookie?.split('; ')?.find(s => s.startsWith('__Secure-SID'))?.split('=')?.pop();
  if (token) {
    setToken(token);
    return token;
  }
  return window?.localStorage?.getItem(TOKEN_LOCALSTORAGE_KEY);
};

export const setToken = (token) => window.localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token);
