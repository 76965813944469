import { loadAsyncJsLib } from '@/utils/async';
import { SocialLoginProvider } from '@/plugins/social-login/SocialLoginProvider';

/*global FB*/
export class FacebookProvider extends SocialLoginProvider {
  async install() {
    await loadAsyncJsLib('https://connect.facebook.net/pt_BR/sdk.js');
    FB.init({
      appId: this.clientId,
      cookie: true,
      xfbml: true,
      version: 'v9.0',
    });
  }

  async login() {
    return new Promise(resolve => FB.login(r => resolve(r.authResponse?.accessToken), { scope: 'public_profile,email' }));
  }

  get name() {
    return 'facebook';
  }

  get icon() {
    return require('@/assets/icons/facebook.svg');
  }

  isActive () {
    return false;
  }
}
